import { ChakraProvider, Flex } from '@chakra-ui/react'

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import NavBar from './NavBar';
import MapSearchPage from './Search/MapSearchPage';

function App() {
  const [removeAds, setRemoveAds] = useState(null);

  useEffect(() => {
    // Simulate an API call
    fetch('https://maxmypoint.com/authenticated', {
      credentials: 'include' // Include cookies for cross-origin requests
    })
      .then(response => response.json())
      .then(data => {
        // Assume the API returns a boolean indicating whether to add the script
        console.log('authenticated:', data);
        if (data.authenticated && (data.level === 'Gold' || data.level === 'Platinum')) {
          setRemoveAds(true);
          console.log('removeAds:', removeAds);
        }
        else {
          setRemoveAds(false);
          console.log('removeAds:', removeAds);

          // Add the inline script
          const inlineScript = document.createElement('script');
          inlineScript.type = 'text/javascript';
          inlineScript.setAttribute('data-cfasync', 'false');
          inlineScript.setAttribute('name', 'fsads');
          inlineScript.innerHTML = `
            var freestar = freestar || {};
            freestar.queue = freestar.queue || [];
            freestar.config = freestar.config || {};
            freestar.config.disabledProducts = {
              stickyRail: true,
              sideWall: true,
            };
            freestar.config.enabled_slots = [];
            freestar.initCallback = function() {
              (freestar.config.enabled_slots.length === 0)
                ? freestar.initCallbackCalled = false
                : freestar.newAdSlots(freestar.config.enabled_slots);
            };
          `;
          document.head.appendChild(inlineScript);

        }
      })
      .catch(error => console.error('Error fetching condition:', error));
  }, []);

  useEffect(() => {
    console.log('removeAds:', removeAds);
    if (removeAds) {
      const adsScripts = document.querySelectorAll('script[name="fsads"]');
      console.log('adsScripts:', adsScripts);
      adsScripts.forEach(ad => {
        ad.parentNode.removeChild(ad);
      });
      const adspd = document.querySelectorAll('maxmypoint-com_sticky_pushdown');
      console.log('adsIns:', adspd);
      adspd.forEach(ad => {
        ad.parentNode.removeChild(ad);
      });

      const adsfooter = document.querySelectorAll('fs-sticky-footer');
      console.log('adsfooter:', adsfooter);
      adsfooter.forEach(ad => {
        ad.parentNode.removeChild(ad);
      });
    }
  }, [removeAds]);

  return (
    <HelmetProvider>
      <ChakraProvider>
      {removeAds == false && (
          <Helmet>
            <link
              rel="stylesheet"
              href="https://a.pub.network/maxmypoint-com/cls.css"
            />
            {/* <script
              data-cfasync="false"
              type="text/javascript"
              name="fsads"
              dangerouslySetInnerHTML={{
                __html: `
                  var freestar = freestar || {};
                  freestar.queue = freestar.queue || [];
                  freestar.config = freestar.config || {};
                  freestar.config.disabledProducts = {
                    stickyRail: true,
                    sideWall: true,
                  };
                  freestar.config.enabled_slots = [];
                  freestar.initCallback = function() {
                    (freestar.config.enabled_slots.length === 0)
                      ? freestar.initCallbackCalled = false
                      : freestar.newAdSlots(freestar.config.enabled_slots);
                  };
                `,
              }}
            /> */}
            <script
              src="https://a.pub.network/maxmypoint-com/pubfig.min.js"
              data-cfasync="false"
              async
              name="fsads"
            ></script>
          </Helmet>
        )}
        <Router>
          <Flex direction='column' justify='stretch' height='100%'>
            <NavBar />
            <Routes>
              <Route path='/' element={<MapSearchPage />} />
            </Routes>
          </Flex>
        </Router>
      </ChakraProvider>
    </HelmetProvider>
  );
}

export default App;
